<template>
	<v-toolbar color="white">
    <v-toolbar-title>
			<v-img src="/img/logotipo-600x100.png" max-width="130px" max-height="50px"/>
		</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="logout">
      <v-icon>mdi-power</v-icon>
    </v-btn>
	</v-toolbar>
</template>

<script>
	import { mapGetters } from "vuex";

	export default {
		data: () => ({
			responsive: false,
			responsiveInput: false
		}),

		computed: {
			...mapGetters(["authorized"])
		},

		watch: {
			$route(val) {
				this.title = val.meta.name;
			}
		},

		mounted() {
			this.onResponsiveInverted();
			window.addEventListener("resize", this.onResponsiveInverted);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.onResponsiveInverted);
		},

		methods: {
			onClickBtn() {
				this.setDrawer(!this.$store.state.app.drawer);
			},
			onResponsiveInverted() {
				if (window.innerWidth < 991) {
					this.responsive = true;
					this.responsiveInput = false;
				} else {
					this.responsive = false;
					this.responsiveInput = true;
				}
			},
			logout: function() {
				this.$store.dispatch("logout").then(() => {
					this.$router.push("/");
				});
			}
		}
	};
</script>