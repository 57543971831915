<template>
  <v-container>
    <v-snackbar
      v-model="errorSave"
      color="error"
      top
    >
      <ul>
        <li v-for="(e,idx) in errores" :key="'error-'+idx">{{e}}</li>
      </ul>
    </v-snackbar>
    <v-dialog v-model="agregarVariante" max-width="500" scrollable persistent>
      <v-card>
        <v-card-title>
          Nueva Variante
        </v-card-title>
        <v-card-text>
          <v-select-advance
            v-model="editedItem.marca"
            searchable
            taggable
            :options="marcas"
            label="nombre"
            placeholder="Selecciona Marca"
            @input="filtrarModelos"
          ></v-select-advance>
          <br>
          <v-select-advance
            v-model="editedItem.modelo"
            :disabled="editedItem.marca == null"
            searchable
            taggable
            :options="modelosFiltrados"
            label="nombre"
            placeholder="Selecciona Modelo"
            @input="agregarModelo"
          ></v-select-advance>
          <br>
          <v-select-advance
            v-model="editedItem.tags"
            multiple
            searchable
            taggable
            :options="tags"
            label="nombre"
            placeholder="Selecciona Tags"
            @input="agregarTags"
          ></v-select-advance>
          <br>
          <number-input
            v-model="editedItem.cantidad"
            controls
            size="small"
            :min="1"
            :value="1"
            center
          ></number-input>
          <br>
          <v-file-input
            v-model="editedItem.imagenes"
            show-size
            counter
            multiple
            dense
            prepend-icon="mdi-file-image-outline"
            accept="image/*"
            label="Imágenes"
            @change="generarMinuaturas"
          ></v-file-input>
          <br>
          <v-carousel
            v-model="editedItem.miniatura"
            v-if="editedItem.miniaturas.length > 0"
            height="250"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(b64,idx) in editedItem.miniaturas"
              :key="'miniatura'+idx"
              :src="b64"
              contain
            ></v-carousel-item>
          </v-carousel>
          <br>
          <v-progress-linear v-if="saving" :value="porcentajeCarga"></v-progress-linear>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" :disabled="saving" text @click="resetVariante()">Cancelar</v-btn>
          <v-btn color="green darken-1" text @click="saveVariante()" :disabled="saving" :loading="saving">
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="agregar" max-width="290">
      <v-card>
        <v-card-title>
          {{viewedItem.marca}} {{viewedItem.modelo}}
        </v-card-title>
        <v-card-text>
          <v-img
            min-height="250"
            max-height="250"
            class="white--text align-end"
            :lazy-src="'/lazys/'+viewedItem.miniatura"
            :src="'/thumbnails/'+viewedItem.miniatura"
            contain
          ></v-img>
          ¿Desea Aumentar la Cantidad de Piezas Disponibles en +1?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="closeAgregar()"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="saveAgregar()"
          >
            Aumentar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eliminar" max-width="290">
      <v-card>
        <v-card-title>
          {{viewedItem.marca}} {{viewedItem.modelo}}
        </v-card-title>
        <v-card-text>
          <v-img
            min-height="250"
            max-height="250"
            class="white--text align-end"
            :lazy-src="'/lazys/'+viewedItem.miniatura"
            :src="'/thumbnails/'+viewedItem.miniatura"
            contain
          ></v-img>
          ¿Desea Disminuir la Cantidad de Piezas Disponibles en -1?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="closeDisminuir()"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="saveDisminuir()"
          >
            Disminuir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detalles" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#048A81">
          <v-btn icon dark @click="closeDetalles()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{viewedItem.marca}} {{viewedItem.modelo}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <br>
          <v-carousel
            v-model="imagenDescargaPosicion"
            cycle
            interval="10000"
            height="69vh"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(url,i) in viewedItem.fotos"
              :key="'img'+i"
              :src="'/thumbnails/'+url"
              :lazy-src="'/lazys/'+url"
              contain
            >
            </v-carousel-item>
          </v-carousel>
          <v-col cols="12" sm="12" md="12" lg="12">
            <a :href="'/images/'+viewedItem.fotos[imagenDescargaPosicion]" :download="viewedItem.fotos[imagenDescargaPosicion]">
              <v-btn color="primary" block><v-icon>mdi-download</v-icon> &nbsp;&nbsp; Descargar</v-btn>
            </a>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" class="pa-0 ma-0">
            <v-chip-group
              column
              active-class="primary--text"
            >
              <v-chip v-for="(tag,idx) in viewedItem.tags" :key="'tag-'+idx">
                {{ tag }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col cols="12" md="12" sm="12">
      <v-row class="ma-0 pa-0">
        <v-col :cols="cols3" :md="cols3" :sm="cols3">
          <v-card>
            <v-card-text class="text-center">
              Marcas:
              <h2 class="text-center">
                <ICountUp
                  :delay="2000"
                  :endVal="estadisticas.totalMarcas"
                />
              </h2>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="cols3" :md="cols3" :sm="cols3">
          <v-card>
            <v-card-text class="text-center">
              Modelos:
              <h2 class="text-center">
                <ICountUp
                  :delay="2000"
                  :endVal="estadisticas.totalModelos"
                />
              </h2>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="cols3" :md="cols3" :sm="cols3">
          <v-card>
            <v-card-text class="text-center">
              Variantes:
              <h2 class="text-center">
                <ICountUp
                  :delay="2000"
                  :endVal="estadisticas.totalVariantes"
                />
              </h2>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col :cols="cols3" :md="cols3" :sm="cols3">
          <v-card>
            <v-card-text class="text-center">
              Piezas:
              <h2 class="text-center">
                <ICountUp
                  :delay="2000"
                  :endVal="estadisticas.totalPiezas"
                />
              </h2>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <v-btn color="primary" @click="agregarVariante = true">Nueva Variante</v-btn>
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <v-row class="ma-0 pa-0">
        <v-col :cols="cols2.tags" :md="cols2.tags" :sm="cols2.tags">
          <v-select-advance
            v-model="selected"
            multiple
            searchable
            taggable
            :options="opciones"
            label="nombre"
            placeholder="Selecciona Marca / Modelo / Tags"
            @input="actualizarOptions"
          ></v-select-advance>
        </v-col>
        <v-col :cols="cols2.order" :md="cols2.order" :sm="cols2.order">
          <v-select
            v-model="orden"
            :items="ordenes"
            item-text="nombre"
            item-value="tipo"
            dense
            hide-details
            @change="actualizarOptions"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <v-row dense>
      <v-col v-for="variante in variantes" :key="'v-'+variante.id_marca+'-'+variante.id_modelo+'-'+variante.id_variante" :cols="cols" :md="cols" :sm="cols">
        <v-card
          class="mx-auto"
        >
          <br>
          <v-img
            @click="viewItem(variante)"
            min-height="250"
            max-height="250"
            class="white--text align-end"
            :lazy-src="'/lazys/'+variante.miniatura"
            :src="'/thumbnails/'+variante.miniatura"
            contain
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <v-card-title class="justify-center">{{variante.marca}} {{variante.modelo}}</v-card-title>

          <v-card-subtitle class="text-center ma-0 pa-0">Cantidad: {{variante.cantidad}}</v-card-subtitle>

          <v-card-actions>
            <v-btn
              color="green"
              text
              @click="agregarPieza(variante)"
            >
              Aumentar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="disminuirPieza(variante)"
            >
              Disminuir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="loading">
      <lottie :options="loadingAnimation" :height="170" :width="300" />
    </v-col>
  </v-container>
</template>

<script>
import axios from 'axios'
import ICountUp from 'vue-countup-v2';
import Lottie from 'vue-lottie';
import animationData from '@/assets/car-loop.json';

export default {
  components:{
    ICountUp,
    'lottie': Lottie
  },
  data: () => ({
    loadingAnimation: {animationData: animationData},
    estadisticas:{
      totalMarcas:0,
      totalModelos:0,
      totalVariantes:0,
      totalPiezas:0,
    },
    orden: 1,
    ordenes:[
      {
        tipo: 1,
        nombre: "Fecha ↓",
      },
      {
        tipo: 2,
        nombre: "Fecha ↑",
      },
      {
        tipo: 3,
        nombre: "Cantidad ↓",
      },
      {
        tipo: 4,
        nombre: "Cantidad ↑",
      }
    ],
    opciones:[],
    selected:[],
    page: 1,
    complete: false,
    marcas:[],
    modelos: [],
    tags: [],
    detalles: false,
    agregar: false,
    eliminar: false,
    loaded:0,
    variantes:[],
    loading: false,
    viewedItem: {
      fotos:[]
    },
    editedItem:{
      marca:null,
      modelo: null,
      tags:null,
      cantidad:1,
      imagenes:[],
      miniaturas:[],
      miniatura:0,
    },
    defaultEditedItem:{
      marca:null,
      modelo: null,
      tags:null,
      cantidad:1,
      imagenes:[],
      miniaturas:[],
      miniatura:0,
    },
    agregarVariante:false,
    modelosFiltrados:[],
    loadingAgregarVariante:false,
    loadingAgregar: false,
    loadingDisminuir: false,
    errores: [],
    errorSave: false,
    saving:false,
    imagenDescargaPosicion: 0,
    porcentajeCarga: 0,
  }),
  computed: {
    cols2(){
      if(this.$vuetify.breakpoint.xs) return {tags: 12, order: 12}; 
      else if(this.$vuetify.breakpoint.sm) return {tags: 6, order: 6};
      else if(this.$vuetify.breakpoint.md) return {tags: 8, order: 4};
      else return {tags: 10, order: 2};
    },
    cols () {
      if(this.$vuetify.breakpoint.xs) return 12; 
      else if(this.$vuetify.breakpoint.sm) return 6;
      else if(this.$vuetify.breakpoint.md) return 4;
      else return 3;
    },
    cols3 () {
      if(this.$vuetify.breakpoint.xs) return 6; 
      else if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) return 6;
      else return 3;
    },
  },
  created(){
    this.getEstadisticas();
  },
  watch: {
    loaded(){
      if(this.loaded == 3){
        this.opciones = this.marcas.concat(this.modelos,this.tags);
      }
    }
  },
  methods: {
    mostrarError(){
      this.$swal.fire(
        'Oops...',
        'Se ha producido un Error',
        'error'
      );
    },
    guardadoSatisfactorio(){
      this.$swal.fire(
        'Exito!',
        'Variante Agregada Satisfactoriamente',
        'success',
      );
    },
    actualizarOptions(){
      var marca = this.selected.find(element => {
        return typeof element.id_marca !== 'undefined' && typeof element.id_modelo === 'undefined';
      });

      var modelo = this.selected.find(element => {
        return typeof element.id_modelo !== 'undefined';
      });

      var idsTags = this.selected.filter(element => {
        return typeof element.id_tag !== 'undefined';
      })
      .map(element => {
        return element.id_tag;
      });

      var tags = this.tags.filter(element => {
        return idsTags.indexOf(element.id_tag) == -1;
      });

      if(typeof marca !== 'undefined'){
        if(typeof modelo !== 'undefined'){
          this.opciones = tags;
          this.variantes = [];
          this.page = 1;
          this.complete = false;
          this.getAutos();
        }
        else{
          var modelos = this.modelos.filter(element => {
            return element.id_marca == marca.id_marca;
          });
          this.opciones = modelos.concat(tags);
          this.variantes = [];
          this.page = 1;
          this.complete = false;
          this.getAutos();
        }
      }
      else{
        if(typeof modelo !== 'undefined'){
          marca = this.marcas.find(element => {
            return element.id_marca === modelo.id_marca;
          });
          this.selected = [marca].concat(this.selected);
          this.actualizarOptions();
        }
        else{
          this.opciones = this.marcas.concat(this.modelos,tags);
          this.variantes = [];
          this.page = 1;
          this.complete = false;
          this.getAutos();
        }
      }
    },
    getMarcas(){
      axios
      .get("/Marcas")
      .then(response => {
        this.marcas = response.data;
        this.loaded += 1;
      })
      .catch(error => {
        console.log(error);
        this.mostrarError();
      })
    },
    getModelos(){
      axios
      .get("/Modelos")
      .then(response => {
        this.modelos = response.data;
        this.loaded += 1;
      })
      .catch(error => {
        console.log(error);
        this.mostrarError();
      })
    },
    getTags(){
      axios
      .get("/Tags")
      .then(response => {
        this.tags = response.data;
        this.loaded += 1;
      })
      .catch(error => {
        console.log(error);
        this.mostrarError();
      })
    },
    getEstadisticas(){
      axios
      .get("/Variantes/Estadisticas")
      .then(response => {
        this.estadisticas = response.data;
      })
      .catch(error =>{
        console.log(error);
        this.mostrarError();
      });
    },
    getAutos(){
      if(this.complete == true){
        this.loading = false;
        return;
      }
      var id_marca = 0;
      var id_modelo = 0;
      var ids_tags = [];

     var marca = this.selected.find(element => {
        return typeof element.id_marca !== 'undefined' && typeof element.id_modelo === 'undefined';
      });
      if(typeof marca !== 'undefined'){
        id_marca = marca.id_marca;
      }

      var modelo = this.selected.find(element => {
        return typeof element.id_modelo !== 'undefined'; 
      });
      if(typeof modelo !== 'undefined'){
        id_modelo = modelo.id_modelo;
      }

      ids_tags = this.selected.filter(element => {
        return typeof element.id_tag !== 'undefined';
      })
      .map(element => {
        return element.id_tag;
      });

      var parametros = "";
      var numPars = 0;

      if(this.page > 1){
        parametros = "pagina="+this.page;
        numPars += 1;
      }
      
      if(id_marca > 0){
        if(numPars > 0) parametros += "&";
        parametros += "marca="+id_marca;
        numPars += 1;
      }

      if(id_modelo > 0){
        if(numPars > 0) parametros += "&";
        parametros += "modelo="+id_modelo;
        numPars += 1;
      }

      if(ids_tags.length > 0){
        if(numPars > 0) parametros += "&";
        parametros += "tags="+ids_tags.join()
        numPars += 1;
      }

      if(numPars > 0) parametros += "&";
        parametros += "orden="+this.orden;

      if(parametros != "") parametros = "?"+parametros;

      this.loading = true;
      axios
      .get("/Variantes"+parametros)
      .then(response => {
        this.loading = false;
        if(response.data.length < 10){
          this.complete = true;
        }
        this.page += 1;
        this.variantes = this.variantes.concat(response.data);
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
        this.mostrarError();
      });
    },
    agregarPieza(item){
      this.agregar = true;
      this.viewedItem = item;
    },
    saveAgregar(){
      this.loadingAgregar = true;
      axios
      .put("/Variantes/Aumentar/"+this.viewedItem.id_variante)
      .then( () =>{
        this.loadingAgregar = false;
        this.agregar = false;
        this.getEstadisticas();

        var idx = this.variantes.findIndex(element => element.id_variante === this.viewedItem.id_variante);
        this.variantes[idx].cantidad += 1;
      })
      .catch(error => {
        this.loadingAgregar = false;
        console.log(error);
        this.mostrarError();
      });
    },
    closeAgregar(){
      this.agregar = false;
    },
    disminuirPieza(item){
      this.eliminar = true;
      this.viewedItem = item;
    },
    saveDisminuir(){
      this.loadingDisminuir = true;
      axios
      .put("/Variantes/Disminuir/"+this.viewedItem.id_variante)
      .then( () =>{
        this.loadingDisminuir = false;
        this.eliminar = false;
        this.getEstadisticas();

        var idx = this.variantes.findIndex(element => element.id_variante === this.viewedItem.id_variante);
        this.variantes[idx].cantidad -= 1;
      })
      .catch(error => {
        this.loadingDisminuir = false;
        console.log(error);
        this.mostrarError();
      });
    },
    closeDisminuir(){
      this.eliminar = false;
    },
    viewItem(item){
      var pos = this.variantes.indexOf(item);
      this.viewedItem = this.variantes[pos];
      this.detalles = true;
    },
    agregarModelo(event){
      if(typeof event === 'string'){
        var modelo = {
          nombre: event
        };

        this.editedItem.modelo = modelo;
      }
    },
    check(){
      var errores = [];

      if(this.editedItem.marca == null || this.editedItem.marca.nombre.length < 2){
        errores.push("Debe de seleccionar una marca");
      }

      if(this.editedItem.modelo == null || this.editedItem.modelo.nombre.length < 2){
        errores.push("Debe de seleccionar un modelo");
      }

      if(this.editedItem.tags == null || this.editedItem.tags.length <= 0){
        errores.push("Debe de seleccionar al menos un tag");
      }

      if(this.editedItem.imagenes.length <= 0){
        errores.push("Debe de seleccionar al menos una imagen");
      }

      return errores;
    },
    saveVariante(){  
      
      var errores = this.check();

      if(errores.length > 0){
        this.errores = errores;
        this.errorSave = true;
        return;
      }

      this.porcentajeCarga = 0;

      let formData = new FormData();

      this.editedItem.imagenes.forEach(img => {
        formData.append('imagenes',img);
      });

      if(typeof this.editedItem.marca.id_marca === 'undefined'){
        formData.append('marca',this.editedItem.marca.nombre);
      }
      else{
        formData.append('id_marca',this.editedItem.marca.id_marca);
      }

      if(typeof this.editedItem.modelo.id_modelo === 'undefined'){
        formData.append('modelo',this.editedItem.modelo.nombre);
        
      }
      else{
        formData.append('id_modelo',this.editedItem.modelo.id_modelo);
      }

      this.editedItem.tags.filter(element => {
        return typeof element.id_tag !== 'undefined'; 
      }).forEach(element => {
        formData.append('ids_tags',element.id_tag);
      });

      this.editedItem.tags.filter(element => {
        return typeof element.id_tag === 'undefined'; 
      }).forEach(element => {
        formData.append('tags',element.nombre);
      });
      
      formData.append('cantidad',this.editedItem.cantidad);

      formData.append('miniatura',this.editedItem.miniatura);

      this.saving = true;

      axios.post( '/Variantes',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function( progressEvent ) {
            this.porcentajeCarga = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
          }.bind(this)
        }
      ).then( () => {
        this.saving = false;
        this.loaded = 0;
        this.resetVariante();
        this.variantes = [];
        this.page = 1;
        this.complete = false;
        this.getAutos();
        this.guardadoSatisfactorio();
        this.getMarcas();
        this.getModelos();
        this.getTags();
      })
      .catch(error => {
        console.log(error);
        this.saving = false;
        this.mostrarError();
      });

    },
    resetVariante(){
      this.editedItem = Object. assign({}, this.defaultEditedItem);
      this.editedItem.miniaturas = null;
      this.editedItem.miniaturas = [];
      this.agregarVariante = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    generarMinuaturas(){
      if(this.editedItem.imagenes.length == 0){
        this.editedItem.miniaturas = [];
        return;
      }
      this.editedItem.imagenes.forEach(imagen => {
        this.getBase64(imagen)
        .then(data => {
          this.editedItem.miniaturas.push(data);
        })
      });
    },
    agregarTags(event){
      var tags = [];
      event.forEach(tag => {
        if(typeof tag === 'string'){
          tags.push({
            nombre: tag
          });
        }
        else{
          tags.push(tag);
        }
        this.editedItem.tags = tags;
      });
    },
    filtrarModelos(event){
      if(typeof event == 'string'){
        this.editedItem.marca = {
          nombre:event
        };
        this.modelosFiltrados = [];
      }
      else{
        this.modelosFiltrados = this.modelos.filter(modelo => {
          return modelo.id_marca == this.editedItem.marca.id_marca;
        });
      }
    },
    scroll () {
      window.onscroll =() => {
        if ((window.innerHeight + window.scrollY + 10) >= document.body.offsetHeight) {
          if(!this.loading){
            this.loading = true;
            this.getAutos();
          }
        }
      };
    },
    closeDetalles(){
      console.log("Error");
      this.detalles = false;
      this.imagenDescargaPosicion = 0;
    }
  },
  mounted() {
    this.getMarcas();
    this.getModelos();
    this.getTags();
    this.scroll();
    this.getAutos();
  }
}
</script>